//
//
//
//
//
//

import CommonCountdown from "~/components/common/CommonCountdown.vue";

export default {
    name: "BannerCountdown",
    components: {CommonCountdown},
    props: {
        countdownInfo: {
            type: Object,
            default: () => { return {} }
        },
    },
    data() {
        return {
            dateNow: 0,
        }
    },
    computed: {
        timerTime() {
            const time = (this.countdownInfo?.countdownTime ?? 0);
            return time > 0 ? time*1000 : 0;
        }
    },
    mounted() {
        this.dateNow = Date.now()
        setInterval(function (){
            this.dateNow = Date.now()
        }.bind(this), 1000)
    }
}
